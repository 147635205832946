import { Column, GridBox } from '@codecademy/gamut';
import { DotLoose } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

const StyledPattern = styled(DotLoose)`
  display: block;
  height: 2rem;
  pointer-events: none;
  color: ${theme.colors['blue-300']};
`;

export const DotLooseDivider: React.FC = () => (
  <GridBox gridTemplateColumns="repeat(12, 1fr)" columnGap={24}>
    <Column
      offset={{ _: 1, md: 2 }}
      size={{ _: 10, md: 8 }}
      px={{ _: 24, md: 64 }}
    >
      <StyledPattern />
    </Column>
  </GridBox>
);
