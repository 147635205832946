import { Box, FlexBox, Text } from '@codecademy/gamut';
import { GridRegular } from '@codecademy/gamut-patterns';
import { Register } from '@mono/ui-login-or-register';

import { Carousel } from './Carousel';
import { CarouselItem } from './Carousel/consts';

export const HeroSignUpSection: React.FC<{
  title: string;
  carousel: CarouselItem[];
  isUserInIndia: boolean;
  showSignUpCtaVariant: boolean;
}> = ({ title, carousel, isUserInIndia, showSignUpCtaVariant }) => (
  <Box position="relative">
    <GridRegular
      position="absolute"
      width="50%"
      display={{ _: 'none', md: 'block' }}
      zIndex={-1}
    />
    <FlexBox
      flexDirection={{ _: 'column', md: 'row' }}
      maxWidth={1440}
      mx="auto"
    >
      <Box width={{ _: '100%', md: '50%' }} position="relative">
        <GridRegular
          position="absolute"
          display={{ _: 'block', md: 'none' }}
          zIndex={-1}
        />
        <Carousel carousel={carousel} />
      </Box>
      <Box width={{ _: '100%', md: '50%' }} pr={{ _: 0, md: 64, lg: 96 }}>
        <Box
          px={{ _: 16, md: 32 }}
          pt={{ _: 48, md: 38 as 0, lg: 64, xl: 80 as 0 }}
          pb={{ _: 32, md: 0 }}
        >
          <FlexBox column maxWidth={400} minHeight={400} mx="auto">
            <Register
              buttonText={showSignUpCtaVariant ? 'Start for free' : 'Sign up'}
              renderTitle={() => (
                <Text as="h1" variant="title-md" lineHeight="base" mb={32}>
                  {title}
                </Text>
              )}
              variant="basic"
              redirectUrl="/welcome/find-a-course"
              captureMobile={isUserInIndia}
            />
          </FlexBox>
        </Box>
      </Box>
    </FlexBox>
  </Box>
);
