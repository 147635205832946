import {
  Box,
  Column,
  ContentContainer,
  FlexBox,
  LayoutGrid,
  Text,
} from '@codecademy/gamut';

import { LearnerCard } from './LearnerCard';

export interface LearnerStory {
  heading: string;
  description: string;
  callToActionUrl: string;
  imageUrl: string;
}
export interface LearnerStoriesSection {
  heading: string;
  description: string;
  stories: LearnerStory[];
}

export const LearnerStories: React.FC<LearnerStoriesSection> = ({
  heading,
  description,
  stories,
}) => {
  return (
    <Box my={120 as 0}>
      <FlexBox flexDirection="column" alignItems="center">
        <Text
          as="h2"
          variant="title-xl"
          fontFamily="accent"
          fontWeight={400}
          mb={24}
        >
          {heading}
        </Text>
        <Text variant="p-large" maxWidth={600} mb={40}>
          {description}
        </Text>
      </FlexBox>
      <FlexBox alignItems="center">
        <ContentContainer>
          <LayoutGrid>
            <Column size={12}>
              <LayoutGrid as="ul" rowGap={32} columnGap={16} m={0} p={0}>
                {stories?.map((story, index) => (
                  <Column
                    as="li"
                    size={{ xs: 12, sm: 6, lg: 4 }}
                    key={story.heading}
                    ml={8}
                    alignItems="start"
                  >
                    <LearnerCard story={story} index={index} />
                  </Column>
                ))}
              </LayoutGrid>
            </Column>
          </LayoutGrid>
        </ContentContainer>
      </FlexBox>
    </Box>
  );
};
