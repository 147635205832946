import { Badge, FlexBox, Text } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

const sharedPortraitImageStyles = css`
  border: 1px solid ${theme.colors['border-primary']};
  max-width: 40rem;
  ${theme.breakpoints.xl} {
    max-width: 18rem;
    max-height: 18rem;
  }
  ${theme.breakpoints.sm} {
    max-height: 18rem;
  }
`;

const StyledImage = styled.img`
  height: 82px;
  width: 82px;
  border-radius: 50%;
  border: 2px solid #fff !important;
  ${sharedPortraitImageStyles}
  object-fit: cover;
`;

interface Learner {
  id: string;
  heading: string;
  description: string;
  hike: string;
  degree: string;
  image: string;
}

export const LearnerCard: React.FC<{ learner: Learner }> = ({ learner }) => (
  <FlexBox
    key={learner.id}
    flexDirection="column"
    border={1}
    borderRadius="sm"
    px={16}
    py={16}
    pt={12}
    pb={12}
    justifyContent="space-between"
    alignItems="center"
    textAlign="center"
    width="181px"
    background="rgba(255, 255, 255, 0.09)"
  >
    <StyledImage alt="learner portrait" src={learner.image} />
    <Text variant="p-base" maxWidth={309} fontWeight={400} pt={8}>
      {learner.heading}
    </Text>
    <Text variant="p-small" maxWidth={279} fontWeight={700} flexGrow={1}>
      {learner.description}
    </Text>
    <FlexBox
      flexDirection="column"
      mt={12}
      justifyContent="center"
      alignItems="center"
      bottom={12}
    >
      <Badge
        variant="accent"
        fontFamily="accent"
        maxWidth={279}
        fontSize={14}
        mb={8}
      >
        {learner.hike}
      </Badge>
      <Badge variant="tertiary" fontFamily="accent" size="sm">
        {learner.degree}
      </Badge>
    </FlexBox>
  </FlexBox>
);
