import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientService } from '@mono/apollo';
import { logError } from '@mono/data-logging';
import {
  Experiment,
  isOptimizelyExperimentActive,
} from '@mono/data-optimizely';
import { redirect } from '@mono/util-next';
import { ReactSDKClient } from '@optimizely/react-sdk';
import { GetServerSidePropsContext } from 'next';

import { defaultCtaBlockData } from './components/CTABlock/consts';
import {
  defaultCarouselItems,
  indiaLearnerStorySlide,
} from './components/HeroSignUpSection/Carousel/consts';
import { defaultPlatformShowcaseData } from './components/PlatformShowcase/consts';
import {
  defaultGoalsData,
  defaultHeroTitle,
  defaultLearnerStoriesData,
  variantLearnerStoriesData,
} from './consts';
import {
  fetchFeaturedContainersCached,
  fetchHomepageContentfulDataCached,
} from './fetchers';
import {
  fetchAndNormalizeGoalsData,
  getHomepageHeroTitle,
  normalizeCarouselData,
  normalizeCtaBlockData,
  normalizeLearnerStoriesData,
  normalizePlatformShowcase,
} from './helpers/contentfulData';
import { queryPricingDetails } from './queries.graphql-gateway';
import {
  NullableCarouselData,
  NullableCtaBlock,
  NullableExperimentalHeroTitle,
  NullableLearnerStoriesData,
  NullablePlatformShowcaseData,
  RawGoal,
} from './types';

export const getHomepageProps = async (
  client: ApolloClient<NormalizedCacheObject>,
  context: GetServerSidePropsContext & {
    optimizely?: ReactSDKClient;
    user?: { anonymous?: boolean };
  }
) => {
  if (context.user && !context.user.anonymous) {
    return redirect('/learn');
  }

  const isUserInIndia = !!context.req.headers['cf-ipcountry']?.includes('IN');

  const defaultCarouselLocalized = isUserInIndia
    ? [indiaLearnerStorySlide, ...defaultCarouselItems]
    : defaultCarouselItems;

  try {
    const [
      homepage,
      {
        data: { pricing: pricingData },
      },
      featuredContainers,
    ] = await Promise.all([
      fetchHomepageContentfulDataCached(client, isUserInIndia),
      client.query({
        query: queryPricingDetails,
        context: {
          service: ApolloClientService.GraphqlGateway,
        },
      }),
      fetchFeaturedContainersCached(client),
    ]);

    const learnerStories = !homepage?.learnerStories
      ? defaultLearnerStoriesData
      : normalizeLearnerStoriesData(
          homepage.learnerStories as NullableLearnerStoriesData,
          context.optimizely
        );

    const goals = !homepage?.goalsCollection
      ? defaultGoalsData
      : await fetchAndNormalizeGoalsData(
          client,
          homepage.goalsCollection.items as RawGoal[]
        );

    const platformShowcase = !homepage?.platformShowcase
      ? defaultPlatformShowcaseData
      : normalizePlatformShowcase(
          homepage.platformShowcase as NullablePlatformShowcaseData,
          context.optimizely
        );

    const heroTitle = getHomepageHeroTitle(
      homepage?.heroTitle,
      homepage?.experimentalHeroTitle as NullableExperimentalHeroTitle,
      context.optimizely
    );

    const carousel = normalizeCarouselData(
      homepage?.carousel as NullableCarouselData,
      defaultCarouselLocalized,
      context.optimizely
    );

    const ctaBlock = !homepage?.ctaBlock
      ? defaultCtaBlockData
      : normalizeCtaBlockData(
          homepage.ctaBlock as NullableCtaBlock,
          context.optimizely
        );

    const isInHomepageHeroSignUpCtaVariant = isOptimizelyExperimentActive(
      context.optimizely,
      Experiment.HOMEPAGE_HERO_SIGN_UP_CTA
    );

    const isInHomepageRevampExperiment = isOptimizelyExperimentActive(
      context.optimizely,
      Experiment.CYCLE_H_HOMEPAGE_REVAMP
    );

    const showRevampedHomepage = isInHomepageRevampExperiment && !isUserInIndia;

    return {
      props: {
        contentfulData: {
          heroTitle,
          carousel,
          learnerStories: showRevampedHomepage
            ? variantLearnerStoriesData
            : learnerStories,
          goals,
          platformShowcase,
          ctaBlock,
        },
        isUserInIndia,
        showSignUpCtaVariant: isInHomepageHeroSignUpCtaVariant,
        showRevampedHomepage,
        featuredContainers,
        ...pricingData,
      },
    };
  } catch (err) {
    logError(err, { message: `Homepage fetch error: ${err}` });
  }

  return {
    props: {
      contentfulData: {
        heroTitle: defaultHeroTitle,
        carousel: defaultCarouselLocalized,
        learnerStories: defaultLearnerStoriesData,
        goals: defaultGoalsData,
        platformShowcase: defaultPlatformShowcaseData,
        ctaBlock: defaultCtaBlockData,
      },
      isUserInIndia,
      showSignUpCtaVariant: false,
      showRevampedHomepage: false,
      featuredContainers: [], // TODO: provide default value once we have them
    },
  };
};
