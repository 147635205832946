import { Box, ContentContainer, Text } from '@codecademy/gamut';
import { Background } from '@codecademy/gamut-styles';
import { CourseData, PathData } from '@mono/data-curriculum-cards';
import { useState } from 'react';

import { DesktopGoalSection } from './DesktopGoalSection';
import { MobileGoalSection } from './MobileGoalSection';

export interface CategoryData {
  slug: string;
  title: string;
  categoryType: string;
}

export interface RelatedContent {
  courses: CourseData[];
  paths: PathData[];
  categories: CategoryData[];
}

export interface Goal {
  title: string;
  trackingTitle: string;
  description: string;
  relatedContent: RelatedContent;
}

export const GoalSection: React.FC<{ goals: Goal[] }> = ({ goals }) => {
  const goalSectionState = useState(0);

  return (
    <Background position="relative" bg="navy">
      <ContentContainer pt={64}>
        <Text fontFamily="accent" as="p" textAlign="center">
          Start Learning
        </Text>
        <Text as="h2" textAlign="center">
          Popular Courses
        </Text>
        <Box display={{ _: 'none', lg: 'block' }}>
          <DesktopGoalSection goals={goals} goalState={goalSectionState} />
        </Box>
        <Box display={{ _: 'block', lg: 'none' }}>
          {/* This section is for both mobile and tablet */}
          <MobileGoalSection goals={goals} goalState={goalSectionState} />
        </Box>
      </ContentContainer>
    </Background>
  );
};
