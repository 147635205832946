import { Box, Column, LayoutGrid } from '@codecademy/gamut';
import { VariableCurriculumCard } from '@mono/ui-components';
import React from 'react';

import { trackingPageName } from '../../../tracking';
import { RelatedContent } from '..';
import { LinkedHubCard } from '../LinkedHubCard';

const CARD_HEIGHT = 302;

interface CardAreaProps {
  content: RelatedContent;
}

export const Cards: React.FC<React.PropsWithChildren<CardAreaProps>> = ({
  content: { courses, paths, categories },
}) => {
  return (
    <>
      <LayoutGrid
        rowGap={{ _: 16, sm: 48 }}
        columnGap={{ _: 16, sm: 48 }}
        pt={16}
        pb={{ _: 32, sm: 48 }}
        px={0}
        as="ul"
      >
        {courses.map((course) => (
          <Column size={{ _: 12, sm: 6 }} key={course.slug} as="li">
            <Box height={CARD_HEIGHT} width="auto" minWidth={{ md: 422 }}>
              <VariableCurriculumCard
                content={course}
                key={course.id}
                trackingData={{
                  page_name: trackingPageName,
                  context: 'homepage_content_link',
                }}
              />
            </Box>
          </Column>
        ))}
        {paths.map((path) => (
          <Column size={{ _: 12, sm: 6 }} key={path.slug} as="li">
            <Box height={CARD_HEIGHT} width="auto">
              <VariableCurriculumCard
                content={path}
                key={path.id}
                trackingData={{
                  page_name: trackingPageName,
                  context: 'homepage_content_link',
                }}
              />
            </Box>
          </Column>
        ))}
        {categories.map((category) => (
          <Column size={{ _: 12, sm: 6 }} key={category.slug} as="li">
            <LinkedHubCard
              item={category}
              trackingData={{
                page_name: trackingPageName,
                context: 'homepage_content_link',
              }}
            />
          </Column>
        ))}
      </LayoutGrid>
    </>
  );
};
