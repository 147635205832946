import {
  Box,
  GridBox,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  TextButton,
} from '@codecademy/gamut';
import { AddBoldIcon } from '@codecademy/gamut-icons';
import { skillTagIconsMap } from '@mono/ui-components';
import { useState } from 'react';

import { trackingPageName, trackUserClick } from '../../tracking';
import { SkillTile } from './SkillTile';

// placeholder for now until we start using some data
const skillsMenuTabs = [
  {
    label: 'Top subjects',
    skills: [
      {
        title: 'Code foundations',
        slug: 'code-foundations',
        classification: 'subject',
      },
      {
        title: 'Web design',
        slug: 'web-design',
        classification: 'subject',
      },
      {
        title: 'Web development',
        slug: 'web-development',
        classification: 'subject',
      },
      {
        title: 'Python',
        slug: 'python',
        classification: 'language',
      },
      {
        title: 'JavaScript',
        slug: 'javascript',
        classification: 'language',
      },
      {
        title: 'Java',
        slug: 'java',
        classification: 'language',
      },
      {
        title: 'C#',
        slug: 'c-sharp',
        classification: 'language',
      },
      {
        title: 'C++',
        slug: 'c-plus-plus',
        classification: 'language',
      },
      {
        title: 'Go',
        slug: 'go',
        classification: 'language',
      },
      {
        title: 'Swift',
        slug: 'swift',
        classification: 'language',
      },
      {
        title: 'Mobile development',
        slug: 'mobile-development',
        classification: 'subject',
      },
      {
        title: 'Data visualization',
        slug: 'data-visualization',
        classification: 'subject',
      },
      {
        title: 'Data analytics',
        slug: 'data-analytics',
        classification: 'subject',
      },
      {
        title: 'Data science',
        slug: 'data-science',
        classification: 'subject',
      },
      {
        title: 'Machine learning',
        slug: 'machine-learning',
        classification: 'subject',
      },
      {
        title: 'AI development',
        slug: 'artificial-intelligence',
        classification: 'subject',
      },
      {
        title: 'Cloud computing',
        slug: 'cloud-computing',
        classification: 'subject',
      },
      {
        title: 'Game development',
        slug: 'game-development',
        classification: 'subject',
      },
      {
        title: 'Cybersecurity',
        slug: 'cybersecurity',
        classification: 'subject',
      },
      {
        title: 'Ruby',
        slug: 'ruby',
        classification: 'language',
      },
      {
        title: 'IT',
        slug: 'information-technology',
        classification: 'subject',
      },
      {
        title: 'Developer tools',
        slug: 'developer-tools',
        classification: 'subject',
      },
      {
        title: 'Computer science',
        slug: 'computer-science',
        classification: 'subject',
      },
    ],
  },
  {
    label: 'Front end',
    skills: [
      {
        title: 'HTML & CSS',
        slug: 'html-css',
        classification: 'language',
      },
      {
        title: 'JavaScript',
        slug: 'javascript',
        classification: 'language',
      },
      {
        title: 'Kotlin',
        slug: 'kotlin',
        classification: 'language',
      },
      {
        title: 'Mobile Development',
        slug: 'mobile-development',
        classification: 'subject',
      },
      {
        title: 'Swift',
        slug: 'swift',
        classification: 'language',
      },
      {
        title: 'Web design',
        slug: 'web-design',
        classification: 'subject',
      },
      {
        title: 'Web development',
        slug: 'web-development',
        classification: 'subject',
      },
    ],
  },
  {
    label: 'Back end',
    skills: [
      {
        title: 'Bash/Shell',
        slug: 'bash',
        classification: 'language',
      },
      {
        title: 'C',
        slug: 'c',
        classification: 'language',
      },
      {
        title: 'C#',
        slug: 'c-sharp',
        classification: 'language',
      },
      {
        title: 'C++',
        slug: 'c-plus-plus',
        classification: 'language',
      },
      {
        title: 'Cloud computing',
        slug: 'cloud-computing',
        classification: 'subject',
      },
      {
        title: 'Computer science',
        slug: 'computer-science',
        classification: 'subject',
      },
      {
        title: 'Data engineering',
        slug: 'data-engineering',
        classification: 'subject',
      },
      {
        title: 'DevOps',
        slug: 'devops',
        classification: 'subject',
      },
      {
        title: 'Go',
        slug: 'go',
        classification: 'language',
      },
      {
        title: 'Java',
        slug: 'java',
        classification: 'language',
      },
      {
        title: 'PHP',
        slug: 'php',
        classification: 'language',
      },
      {
        title: 'Python',
        slug: 'python',
        classification: 'language',
      },
      {
        title: 'Ruby',
        slug: 'ruby',
        classification: 'language',
      },
      {
        title: 'SQL',
        slug: 'sql',
        classification: 'language',
      },
    ],
  },
  {
    label: 'Data science',
    skills: [
      {
        title: 'Data analytics',
        slug: 'data-analytics',
        classification: 'subject',
      },
      {
        title: 'Data engineering',
        slug: 'data-engineering',
        classification: 'subject',
      },
      {
        title: 'Data visualization',
        slug: 'data-visualization',
        classification: 'subject',
      },
      {
        title: 'Machine learning',
        slug: 'machine-learning',
        classification: 'subject',
      },
      {
        title: 'Math',
        slug: 'math',
        classification: 'subject',
      },
      {
        title: 'Python',
        slug: 'python',
        classification: 'language',
      },
      {
        title: 'R',
        slug: 'r',
        classification: 'language',
      },
      {
        title: 'SQL',
        slug: 'sql',
        classification: 'language',
      },
    ],
  },
  {
    label: 'Certification paths',
    skills: [
      {
        title: 'AI development',
        slug: 'artificial-intelligence',
        classification: 'subject',
      },
      {
        title: 'Cloud computing',
        slug: 'cloud-computing',
        classification: 'subject',
      },
      {
        title: 'Cybersecurity',
        slug: 'cybersecurity',
        classification: 'subject',
      },
      {
        title: 'Data science',
        slug: 'data-science',
        classification: 'subject',
      },
      {
        title: 'DevOps',
        slug: 'devops',
        classification: 'subject',
      },
      {
        title: 'IT',
        slug: 'information-technology',
        classification: 'subject',
      },
      {
        title: 'Professional skills',
        slug: 'professional-skills',
        classification: 'subject',
      },
    ],
  },
];

export const SkillsMenuTabs: React.FC<{
  skillsVisiblePerTab: number;
  isMobile?: boolean;
}> = ({ skillsVisiblePerTab, isMobile }) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [numOfVisibleSkills, setNumOfVisibleSkills] =
    useState<number>(skillsVisiblePerTab);

  const handleLoadMore = () => {
    const newNumOfVisibleSkills = Math.min(
      numOfVisibleSkills + skillsVisiblePerTab,
      skillsMenuTabs[activeTabIndex].skills.length
    );

    setNumOfVisibleSkills(newNumOfVisibleSkills);
  };

  const visibleSkills = [...skillsMenuTabs[activeTabIndex].skills].slice(
    0,
    numOfVisibleSkills
  );

  return (
    <Tabs
      index={activeTabIndex}
      onChange={(index: number) => {
        setActiveTabIndex(index);
        setNumOfVisibleSkills(skillsVisiblePerTab);
        trackUserClick({
          page_name: trackingPageName,
          target: `skills_menu_tab_${skillsMenuTabs[index].label}`,
          context: 'homepage_skills_menu_section',
        });
      }}
    >
      <TabList overflowX={{ _: 'scroll', md: 'initial' }}>
        {skillsMenuTabs.map(({ label }, i) => {
          const isTabActive = i === activeTabIndex;
          return (
            <Tab key={`tab-${label}`} aria-expanded={isTabActive}>
              <Text alignSelf="center" fontSize={{ _: 14, md: 16 }}>
                {label}
              </Text>
            </Tab>
          );
        })}
      </TabList>
      <TabPanels mt={40}>
        {skillsMenuTabs.map(({ label, skills }) => {
          const hasMoreSkillsToLoad = skills.length > numOfVisibleSkills;

          return (
            <TabPanel key={`panel-${label}`} tabIndex={-1}>
              <GridBox
                as="ul"
                gap={{ _: 16, sm: 32 }}
                gridTemplateColumns={{
                  _: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(4, 1fr)',
                }}
                m={0}
                p={0}
                listStyle="none"
              >
                {visibleSkills.map((skill) => (
                  <Box as="li" key={skill.slug}>
                    <SkillTile
                      {...skill}
                      Icon={
                        skillTagIconsMap[skill.slug] || skillTagIconsMap.default
                      }
                      trackingData={{
                        page_name: trackingPageName,
                      }}
                    />
                  </Box>
                ))}
                {!hasMoreSkillsToLoad && (
                  <Box as="li">
                    <SkillTile
                      title="Other subjects"
                      slug=""
                      classification=""
                      Icon={AddBoldIcon}
                      trackingData={{
                        page_name: trackingPageName,
                      }}
                    />
                  </Box>
                )}
              </GridBox>
              {/* TODO: add tracking */}
              {isMobile && hasMoreSkillsToLoad && (
                <Box mt={24} justifySelf="center">
                  <TextButton onClick={() => handleLoadMore()}>
                    Load more
                  </TextButton>
                </Box>
              )}
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};
