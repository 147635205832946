import {
  Box,
  ContentContainer,
  FillButton,
  FlexBox,
  Text,
} from '@codecademy/gamut';
import styled from '@emotion/styled';
import { getRegisterPath } from '@mono/util-url';
import { useEffect, useState } from 'react';

const words = [
  '/self',
  '/career',
  '/skills',
  '/future',
  '/potential',
  '/superpower',
  '/growth',
];
const colors = [
  '#ff8973',
  '#FFE083',
  '#B4D353',
  '#83FFF5',
  '#B3CCFF',
  '#EA6C8B',
  '#CC7BC2',
];

const AnimatedText = styled(Text)<{ textColor: string; width: string }>`
  animation: typing 1s steps(8) forwards,
    blink 1s steps(2, jump-none) infinite alternate;
  animation-delay: 0s, 1s; // delays the blink to happen after typing is done

  width: ${(props) => props.width};
  white-space: nowrap;
  overflow: hidden;
  margin-left: 16px;
  margin-right: 16px;
  border-right: 3px solid ${(props) => props.textColor};
  color: ${(props) => props.textColor};

  @keyframes typing {
    from {
      width: 0;
    }
  }
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    border-right: none;
  }
`;

export const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [key, setKey] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  // Change word every 6 seconds, 6 seconds is minimum for a11y
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
      setKey((prev) => prev + 1);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ContentContainer pt={64} pb={64}>
      <FlexBox
        flexDirection="column"
        width="50%"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        aria-live={isFocused ? 'polite' : 'off'}
      >
        <Text variant="title-xxl" fontFamily="accent" fontWeight={400} as="h1">
          <Box background="text-accent" mb={16} width="fit-content">
            Develop your
          </Box>
          <Box background="black" width="fit-content" mb={96}>
            <AnimatedText
              key={key}
              mt={16}
              textColor={colors[currentIndex]}
              width={`${words[currentIndex].length + 0.5}ch`}
            >
              {words[currentIndex]}
            </AnimatedText>
          </Box>
        </Text>
        <FlexBox flexDirection="column" maxWidth={418}>
          <Text variant="title-md" fontWeight={400} mb={24}>
            Learn tech skills, grow your career, unlock new opportunities
          </Text>
          <FillButton href={getRegisterPath()} width="fit-content">
            Start learning
          </FillButton>
        </FlexBox>
      </FlexBox>
    </ContentContainer>
  );
};
