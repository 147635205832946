import { HomepageSearchResults } from '@mono/brand';
import { ExternalPathClassification } from '@mono/data-codegen/client/graphql-gateway/graphql';
import {
  CurriculumCardData,
  extCourseToCourseData as LibExtCourseToCourseData,
  isRawExternalCertPath,
  toExternalCertPathData as LibExternalCertPathData,
  toPathData as LibToPathData,
  trackToCourseData as LibTrackToCourseData,
} from '@mono/data-curriculum-cards';
import { logError } from '@mono/data-logging';

export const convertRawHomepageSearchResults = (
  results: HomepageSearchResults['top']
): CurriculumCardData[] => {
  const finalList: CurriculumCardData[] = [];

  results.forEach((item) => {
    switch (item.type) {
      case 'Course':
        finalList.push(trackToCourseData(item));
        break;
      case 'Path':
        finalList.push(toPathData(item));
        break;
      case 'ExternalCourse':
        finalList.push(extCourseToCourseData(item));
        break;
      case 'ExternalCertPath':
        finalList.push(toExternalCertPathData(item));
        break;
      default:
        logError(
          `Unrecognized homepage search catalog content item. Data: ${JSON.stringify(
            item
          )}`
        );
    }
  });

  return finalList;
};

// Helper functions for type conversion

const trackToCourseData = (
  raw: HomepageSearchResults['top'][number]
): CurriculumCardData => {
  return LibTrackToCourseData({
    ...raw,
    grantsCertificate: false, // simple curriculum card doesn't use this
    metrics: {
      medianDurationHours: raw.medianDurationHours,
    },
    contentModuleIds: [], // siteSearch query doesn't expose this data
    __typename: 'Track',
  });
};

const toPathData = (
  raw: HomepageSearchResults['top'][number]
): CurriculumCardData => {
  return LibToPathData({
    ...raw,
    trackCount: raw.courseCount || 0,
    metrics: {
      medianDurationHours: raw.medianDurationHours,
    },
    __typename: 'Path',
  });
};

const extCourseToCourseData = (
  raw: HomepageSearchResults['top'][number]
): CurriculumCardData => {
  return LibExtCourseToCourseData({
    ...raw,
    durationHours: raw.medianDurationHours || 0,
    grantsCertificate: false, // simple curriculum card doesn't use this
    __typename: 'ExternalCourse',
  });
};

const toExternalCertPathData = (
  raw: HomepageSearchResults['top'][number]
): CurriculumCardData => {
  const converted = {
    ...raw,
    courseCount: raw.courseCount || 0,
    classification: ExternalPathClassification.CertificationPath,
    durationHours: raw.medianDurationHours || 0,
    certificationProvider: null, // simple curriculum card doesn't use this
    __typename: 'ExternalPath' as const,
  };

  // just for TS to be happy until we have more types of external paths
  if (!isRawExternalCertPath(converted)) {
    throw new Error('Expected raw content to be an ExternalCertPath');
  }
  return LibExternalCertPathData(converted);
};
