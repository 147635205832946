import { Box, FlexBox, Text } from '@codecademy/gamut';
import { DiagonalBDense } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { trackUserClick } from '../../tracking';
import { WhiteTextAnchor } from '../styles';
// these will come from static assets when we get the final images
import Learner0 from './assets/learner0.png';
import Learner1 from './assets/learner1.png';
import Learner2 from './assets/learner2.png';
import Quote from './assets/quote.svg';
import { LearnerStory } from './index';

export interface LearnerCardProps {
  story: LearnerStory;
  index: number; // wont need index once images have static urls saved
}

const Image = Box.withComponent('img');
const learnerImages = [Learner0, Learner1, Learner2];

const StyledDiagonalBDense = styled(DiagonalBDense)`
  border-radius: 8px;
`;

const LowerCardBody = styled(FlexBox)`
  border-radius: 0 0 8px 8px;
`;

export const LearnerCard: React.FC<LearnerCardProps> = ({ story, index }) => {
  return (
    <Box position="relative" maxWidth={{ _: '100%', md: 393 }}>
      <StyledDiagonalBDense
        zIndex={0}
        dimensions={1}
        position="absolute"
        top="1rem"
        left="-1rem"
      />
      <Box
        background="transparent"
        border={1}
        borderColor="border-tertiary"
        borderRadius="lg"
        height="100%"
        zIndex={1}
        position="relative"
      >
        <FlexBox flexDirection="column" justifyContent="flex-end">
          <FlexBox justifyContent="flex-end">
            <Image
              alt="learner portrait"
              src={learnerImages[index]}
              loading="lazy"
              maxWidth="100%"
              pt={40}
            />
          </FlexBox>
          <LowerCardBody
            borderTop={1}
            borderColor="border-tertiary"
            background={theme.colors['navy-800']}
            flexDirection="column"
            height="50%"
            p={24}
          >
            <Text screenreader>{`Quote by ${story.description}`}</Text>
            <Text mb={24}>
              <Image
                src={Quote}
                aria-hidden
                width={44}
                height={26}
                position="absolute"
                mt={-40 as 0}
              />
              {story.heading}
              <WhiteTextAnchor
                data-testid="learner-card"
                href={story.callToActionUrl}
                onClick={() =>
                  trackUserClick({
                    target: 'story',
                    context: 'learner_stories',
                    name: story.heading,
                  })
                }
                ml={4}
              >
                Read more
              </WhiteTextAnchor>
            </Text>
            <Text aria-hidden>{`- ${story.description}`}</Text>
          </LowerCardBody>
        </FlexBox>
      </Box>
    </Box>
  );
};
