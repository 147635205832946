import { Anchor, Box, FlexBox, GridBox, Text } from '@codecademy/gamut';
import {
  MiniChevronDownIcon,
  MiniChevronRightIcon,
} from '@codecademy/gamut-icons';
import { css, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { MouseEvent, useEffect, useRef, useState } from 'react';

import { trackUserClick } from '../../../tracking';
import { Goal } from '..';

const TabList = styled(GridBox)(
  css({
    listStyleType: 'none',
    listStylePosition: 'inside',
  })
);

const StyledAnchor = styled(Anchor)`
  :hover {
    color: white;
  }
  // Slightly different padding for top and bottom because of border top combined with set TabList height
  padding-top: 2rem;
  padding-bottom: 2rem;
  ${theme.breakpoints.xs} {
    transition: 0.3s all ease;
  }
`;

const iconStyle = () =>
  css({
    height: '1rem',
    width: '1rem',
  });
const StyledChevronDownIcon = styled(MiniChevronDownIcon)`
  ${iconStyle};
  // zIndex needed so we don't nest interactive elements (and can access it via event.target)
  z-index: 1;
`;
const StyledChevronRightIcon = styled(MiniChevronRightIcon)`
  ${iconStyle}
`;

interface GoalTabsProps {
  currentTabIndex: number;
  setCurrentTabIndex: (tab: number) => void;
  goals: Goal[];
}

export const GoalTabs: React.FC<React.PropsWithChildren<GoalTabsProps>> = ({
  currentTabIndex,
  children,
  setCurrentTabIndex,
  goals,
}) => {
  const [mobileMinimized, setMobileMinimized] = useState(false);
  const [tabHasBeenClicked, setTabHasBeenClicked] = useState(false);
  const itemEls = useRef<HTMLDivElement[] | null[]>([]);

  const sectionOffset = 950;

  useEffect(() => {
    if (itemEls.current[currentTabIndex] && tabHasBeenClicked) {
      const offsetTop = itemEls.current[currentTabIndex]?.offsetTop;
      if (offsetTop) {
        // Can't use window.scroll because 'behavior' is not supported in iOS for smooth scrolling
        const currentPos = window.pageYOffset;
        let start = 0;
        const time = 600;
        const endingPosition = offsetTop + sectionOffset;
        const step = (currentTime: number) => {
          start = !start ? currentTime : start;
          const progress = currentTime - start;
          if (currentPos < offsetTop) {
            window.scrollTo(
              0,
              ((endingPosition - currentPos) * progress) / time + currentPos
            );
          } else {
            window.scrollTo(
              0,
              currentPos - ((currentPos - endingPosition) * progress) / time
            );
          }
          if (progress < time) {
            window.requestAnimationFrame(step);
          } else {
            window.scrollTo(0, endingPosition);
          }
        };
        window.requestAnimationFrame(step);
      }
    }
  }, [currentTabIndex, tabHasBeenClicked, sectionOffset]);

  const tabClick = (
    index: number,
    target: string,
    targetEl: HTMLInputElement | null
  ) => {
    setTabHasBeenClicked(true);
    if (targetEl?.getAttribute('name') === 'minimize') {
      setMobileMinimized(true);
    } else {
      setMobileMinimized(false);
      if (currentTabIndex !== index) {
        trackUserClick({
          target,
          context: 'homepage_content_goals',
        });
        setCurrentTabIndex(index);
      }
    }
  };

  const renderTabs = (mobile: boolean) => (
    <TabList
      as="ul"
      p={0}
      gridTemplateColumns="minmax(0, 1fr)"
      width={{ _: '97%', xs: '100%' }}
    >
      {goals.map(({ title, trackingTitle, description }, index) => {
        const selected = currentTabIndex === index;
        return (
          <FlexBox
            as="li"
            borderBottom={1}
            flexDirection="column"
            key={title}
            height="100%"
            ref={(element) => {
              if (mobile) {
                itemEls.current[index] = element;
              }
            }}
          >
            <StyledAnchor
              onClick={(e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) =>
                tabClick(index, trackingTitle, e.target as HTMLInputElement)
              }
              variant="interface"
              width="100%"
              height="100%"
              textAlign="left"
              as="button"
            >
              <FlexBox
                flexDirection="column"
                flex={1}
                width={{ _: '97%', sm: '100%' }}
              >
                <FlexBox justifyContent="space-between">
                  <Box display="inline-flex" alignItems="center">
                    <Text fontSize={20} as="h3" variant="title-md">
                      {title}
                    </Text>
                  </Box>

                  <Box display="flex">
                    {selected ? (
                      <StyledChevronDownIcon name="minimize" />
                    ) : (
                      <StyledChevronRightIcon />
                    )}
                  </Box>
                </FlexBox>
                {selected && (
                  <Text
                    as="p"
                    whiteSpace="normal"
                    maxWidth={386}
                    pt={8}
                    variant="p-base"
                    fontSize={16}
                    display={{
                      _: mobileMinimized ? 'none' : 'initial',
                      xs: 'initial',
                    }}
                  >
                    {description}
                  </Text>
                )}
              </FlexBox>
            </StyledAnchor>
            <Box
              display={{
                _: mobileMinimized ? 'none' : 'initial',
                xs: 'initial',
              }}
            >
              {selected && children}
            </Box>
          </FlexBox>
        );
      })}
    </TabList>
  );

  return (
    <>
      <Box display={{ _: 'grid', xs: 'none' }}>{renderTabs(true)}</Box>
      <Box display={{ _: 'none', xs: 'grid' }}>{renderTabs(false)}</Box>
    </>
  );
};
