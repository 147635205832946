import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientService } from '@mono/apollo';
import {
  GetHomepageContentfulQuery,
  GetHomepageContentfulQueryVariables,
} from '@mono/data-codegen/contentful';
import {
  CurriculumCardData,
  fetchCardDataBySlugs,
} from '@mono/data-curriculum-cards';
import { fetchCacher } from '@mono/data-fetch-cachers';
import { logError } from '@mono/data-logging';

import { featuredContainerSlugs } from './consts';
import { getHomepageContentfulQuery } from './queries.contentful';

export const fetchHomepageContentfulDataCached = (
  client: ApolloClient<NormalizedCacheObject>,
  isUserInIndia: boolean
) => {
  const locale = isUserInIndia ? 'en-IN' : 'en-US';
  const isProd = process.env.NODE_ENV === 'production';

  const f = async () => {
    const { data } = await client.query<
      GetHomepageContentfulQuery,
      GetHomepageContentfulQueryVariables
    >({
      query: getHomepageContentfulQuery,
      variables: { locale, preview: !isProd },
      context: { service: ApolloClientService.Contentful },
    });
    return data.homepage;
  };

  return isProd
    ? fetchCacher(`homepage-contentful-${locale}`, f, {
        // 15 minutes, intended to be a happy medium
        // between notable caching gains and not making
        // marketing folks wait long to see updates
        revalidateMs: 15 * 60 * 1000,
      })
    : f();
};

export const fetchFeaturedContainersCached = async (
  client: ApolloClient<NormalizedCacheObject>
) => {
  const f = () => fetchFeaturedContainers(client);
  return fetchCacher('homepage-featured-containers', f, {
    revalidateMs: 60 * 60 * 1000, // 1 hour
  });
};

const fetchFeaturedContainers = async (
  client: ApolloClient<NormalizedCacheObject>
): Promise<CurriculumCardData[]> => {
  try {
    return await fetchCardDataBySlugs(featuredContainerSlugs, client);
  } catch (e) {
    logError(`Failure fetching default search results data: ${e}`);
    return [];
  }
};
