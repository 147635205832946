import { ContentContainer, FlexBox, GridBox, Text } from '@codecademy/gamut';
import React from 'react';

import { Logo, logosList } from './consts';

export const LearnersWorkAt: React.FC = () => {
  return (
    <ContentContainer px={48} pt={48} pb={96}>
      <Text
        as="h2"
        variant="p-base"
        fontFamily="accent"
        textAlign="center"
        mb={24}
      >
        Our learners work at
      </Text>
      <GridBox
        as="ul"
        listStyleType="none"
        p={0}
        m={0}
        gridTemplateColumns={{
          _: 'repeat(3, auto)',
          sm: 'repeat(5, max-content)',
          md: 'repeat(9, max-content)',
        }}
        columnGap={{ sm: 16, md: 48, lg: 80 as 0 }}
        rowGap={{ _: 24, sm: 32 }}
        justifyContent={{
          _: 'stretch',
          xs: 'space-around',
          md: 'center',
        }}
        justifyItems="center"
      >
        {logosList.map((logo: Logo) => {
          return (
            <FlexBox key={logo.alt} as="li" m={0} p={0} center>
              <img src={logo.src} alt={logo.alt} />
            </FlexBox>
          );
        })}
      </GridBox>
    </ContentContainer>
  );
};
