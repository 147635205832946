import {
  Anchor,
  Box,
  ContentContainer,
  FlexBox,
  Text,
} from '@codecademy/gamut';
import { MiniArrowRightIcon } from '@codecademy/gamut-icons';
import { ExLoose } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { getCatalogBasePath } from '@mono/util-url';
import React from 'react';

import {
  MAX_SKILL_TILES_VISIBLE,
  MOBILE_SKILL_TILES_VISIBLE,
} from '../../consts';
import { trackUserClick } from '../../tracking';
import { SkillsMenuTabs } from './SkillsMenuTabs';

const PatternContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;
  color: ${theme.colors.hyper};
`;

const BottomCTA = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const SkillsMenuSection: React.FC = () => {
  return (
    <Box position="relative">
      <PatternContainer>
        <ExLoose />
      </PatternContainer>
      <ContentContainer py={64}>
        <FlexBox
          bg="navy-900"
          p={{ _: 24, md: 48 }}
          flexDirection="column"
          rowGap={40}
        >
          <Text
            as="h2"
            variant="title-lg"
            lineHeight="title"
            fontFamily="accent"
            fontWeight={400}
          >
            Explore top subjects from the catalog
          </Text>
          <Box display={{ _: 'none', md: 'block' }}>
            <SkillsMenuTabs skillsVisiblePerTab={MAX_SKILL_TILES_VISIBLE} />
          </Box>
          <Box display={{ _: 'block', md: 'none' }}>
            <SkillsMenuTabs
              skillsVisiblePerTab={MOBILE_SKILL_TILES_VISIBLE}
              isMobile
            />
          </Box>
          <BottomCTA>
            <Text fontSize={20} p={8}>
              Looking for something else?&nbsp;
            </Text>
            <Anchor
              href={getCatalogBasePath()}
              variant="inline"
              onClick={() =>
                trackUserClick({
                  target: 'view_catalog',
                  context: 'homepage_skills_menu_section',
                })
              }
              p={8}
            >
              <Text pr={8}>Go to the catalog</Text>
              <MiniArrowRightIcon />
            </Anchor>
          </BottomCTA>
        </FlexBox>
      </ContentContainer>
    </Box>
  );
};
