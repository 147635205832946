import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { BLOCKS, Document, NodeData } from '@contentful/rich-text-types';
import {
  CourseData,
  CurriculumCardData,
  PathData,
} from '@mono/data-curriculum-cards';
import { ReactSDKClient } from '@optimizely/react-sdk';

import { CtaBlockData } from '../components/CTABlock';
import defaultLearnersDesktop from '../components/CTABlock/assets/LearnersDesktop.png';
import defaultLearnersMobile from '../components/CTABlock/assets/LearnersMobile.png';
import { defaultCtaBlockData } from '../components/CTABlock/consts';
import { CategoryData, Goal, RelatedContent } from '../components/GoalSection';
import { CarouselItem } from '../components/HeroSignUpSection/Carousel/consts';
import {
  LearnerStoriesSection,
  LearnerStory,
} from '../components/LearnerStories/LegacyLearnerStories.tsx';
import {
  defaultPlatformShowcaseData,
  PlatformFeature,
  PlatformShowcaseCta,
  PlatformShowcaseImage,
  PlatformShowcaseSection,
} from '../components/PlatformShowcase/consts';
import {
  defaultGoalsData,
  defaultHeroTitle,
  defaultLearnerStoriesData,
} from '../consts';
import {
  CarouselVariationContainer,
  CarouselWithoutVariation,
  CtaBlockVariationContainer,
  CtaBlockWithoutVariation,
  ExperimentalHeroTitle,
  ExperimentalHeroTitleVariationContainer,
  ExperimentalHeroTitleWithoutVariation,
  GoalRelatedCurriculumContent,
  LearnerStoryWithoutVariation,
  NullableCarouselData,
  NullableCtaBlock,
  NullableExperimentalHeroTitle,
  NullableLearnerStoriesData,
  NullablePlatformShowcaseData,
  PlatformFeatureRefsCollection,
  PlatformShowcaseCtaRefsCollection,
  PlatformShowcaseFeaturesCollection,
  PlatformShowcaseVariationContainer,
  PlatformShowcaseWithoutVariation,
  PossiblyExperimentalCarousel,
  PossiblyExperimentalCtaBlock,
  PossiblyExperimentalLearnerStory,
  PossiblyExperimentalPlatformShowcase,
  RawGoal,
  RawPlatformFeature,
  RawPlatformFeatureImageRef,
  RawPlatformShowcaseCtaRef,
} from '../types';
import { fetchAllCategoriesDataCached } from './allCategoriesData';
import {
  chooseVariantForUser,
  generateFinalHomepageContentfulSectionArray,
  isVariationContainer,
} from './contentfulOptimizelyMiddleware';
import { fetchGoalsCurriculumContentDataCached } from './goalsCurriculumContentData';

export const normalizeLearnerStoriesData = (
  rawLearnerStoriesData: NullableLearnerStoriesData,
  optimizely: ReactSDKClient | undefined
): LearnerStoriesSection => {
  let stories: LearnerStory[] = [];

  if (!rawLearnerStoriesData.refsCollection?.items.length) {
    ({ stories } = defaultLearnerStoriesData);
  }

  const rawLearnerStories = generateFinalHomepageContentfulSectionArray(
    rawLearnerStoriesData.refsCollection
      ?.items as PossiblyExperimentalLearnerStory[],
    optimizely
  ) as LearnerStoryWithoutVariation[];

  stories = rawLearnerStories.reduce((acc, story) => {
    return [
      ...acc,
      {
        heading: story.heading ?? '',
        description: story.description ?? '',
        callToActionUrl: story.callToActionUrl ?? '',
        imageUrl: story.image?.url ?? '',
      },
    ];
  }, []);

  return {
    heading: rawLearnerStoriesData.heading ?? defaultLearnerStoriesData.heading,
    description:
      rawLearnerStoriesData.description ??
      defaultLearnerStoriesData.description,
    stories: stories.length ? stories : defaultLearnerStoriesData.stories,
  };
};

export const fetchAndNormalizeGoalsData = async (
  client: ApolloClient<NormalizedCacheObject>,
  goals: RawGoal[]
): Promise<Goal[]> => {
  const contentSlugs = getCurriculumContentSlugsFromGoals(goals);

  const [categoriesLookupMap, curriculumContentLookupMap] = await Promise.all([
    fetchAllCategoriesDataCached(client),
    fetchGoalsCurriculumContentDataCached(client, contentSlugs),
  ]);

  if (
    !Object.keys(curriculumContentLookupMap).length ||
    !Object.keys(categoriesLookupMap).length
  ) {
    return defaultGoalsData;
  }

  const goalsData = goals.reduce((acc, item) => {
    // skip goal with no curriculum content
    if (!item.relatedContentCollection?.items?.length) {
      return acc;
    }

    return [
      ...acc,
      {
        title: item.title ?? '',
        trackingTitle: item.trackingTitle ?? '',
        description: item.description ?? '',
        relatedContent: getRelatedContentForGoal(
          item.relatedContentCollection.items as GoalRelatedCurriculumContent[],
          curriculumContentLookupMap,
          categoriesLookupMap
        ),
      },
    ];
  }, [] as Goal[]);

  return goalsData;
};

const getCurriculumContentSlugsFromGoals = (goals: RawGoal[]) => {
  const curriculumContentSlugs: string[] = [];
  for (const goal of goals) {
    if (!goal.relatedContentCollection?.items) {
      return [];
    }

    const { items } = goal.relatedContentCollection;

    for (const item of items) {
      if (
        item &&
        'contentType' in item &&
        item?.contentType !== 'category' &&
        item.slug &&
        !curriculumContentSlugs.includes(item.slug)
      ) {
        curriculumContentSlugs.push(item.slug);
      }
    }
  }

  return curriculumContentSlugs;
};

const getRelatedContentForGoal = (
  goalRelatedContent: GoalRelatedCurriculumContent[],
  curriculumContentLookupMap: Record<string, CurriculumCardData>,
  categoriesLookupMap: Record<string, CategoryData>
) => {
  const relatedContent: RelatedContent = {
    courses: [],
    paths: [],
    categories: [],
  };

  for (const content of goalRelatedContent) {
    if (content?.slug) {
      if (content.contentType === 'course') {
        relatedContent.courses.push(
          curriculumContentLookupMap[content.slug] as CourseData
        );
      } else if (content.contentType === 'path') {
        relatedContent.paths.push(
          curriculumContentLookupMap[content.slug] as PathData
        );
      } else {
        relatedContent.categories.push(categoriesLookupMap[content.slug]);
      }
    }
  }

  return relatedContent;
};

export const normalizePlatformShowcase = (
  rawPlatformShowcase: NullablePlatformShowcaseData,
  optimizely: ReactSDKClient | undefined
): PlatformShowcaseSection => {
  const selectedPlatformShowcase = (
    isVariationContainer(
      rawPlatformShowcase as PossiblyExperimentalPlatformShowcase
    )
      ? chooseVariantForUser(
          optimizely,
          rawPlatformShowcase as PlatformShowcaseVariationContainer
        )
      : rawPlatformShowcase
  ) as PlatformShowcaseWithoutVariation;

  return {
    title: selectedPlatformShowcase.title ?? defaultPlatformShowcaseData.title,
    eyebrow:
      selectedPlatformShowcase.eyebrow ?? defaultPlatformShowcaseData.eyebrow,
    platformFeatures: selectedPlatformShowcase.featuresCollection?.items.length
      ? normalizePlatformFeatures(selectedPlatformShowcase.featuresCollection)
      : defaultPlatformShowcaseData.platformFeatures,
    ctas: selectedPlatformShowcase.refsCollection?.items.length
      ? normalizePlatformShowcaseCtas(selectedPlatformShowcase.refsCollection)
      : defaultPlatformShowcaseData.ctas,
  };
};

const normalizePlatformFeatures = (
  featuresCollection: PlatformShowcaseFeaturesCollection
): PlatformFeature[] =>
  featuresCollection?.items
    .filter(
      (item): item is RawPlatformFeature =>
        !!item && 'type' in item && item.type === 'TitleDescription'
    )
    .map((item) => {
      return {
        title: item.title ?? '',
        trackingTitle: item.trackingTargetTitle ?? '',
        description: item.description ?? '',
        image: item.refsCollection
          ? normalizePlatformFeatureImage(item.refsCollection)
          : { src: '', alt: '' },
      };
    });

const normalizePlatformFeatureImage = (
  refsCollection: PlatformFeatureRefsCollection
): PlatformShowcaseImage => {
  const imagesRef = refsCollection.items.filter(
    (item): item is RawPlatformFeatureImageRef =>
      !!item && 'type' in item && item.type === 'ImageBlock'
  );

  return {
    src: imagesRef[0]?.image?.url ?? '',
    alt: imagesRef[0]?.alt ?? '',
  };
};

const normalizePlatformShowcaseCtas = (
  ctaRefsCollection: PlatformShowcaseCtaRefsCollection
): PlatformShowcaseCta[] =>
  ctaRefsCollection.items
    .filter(
      (item): item is RawPlatformShowcaseCtaRef =>
        !!item && 'type' in item && item.type === 'TextHref'
    )
    .map((item) => {
      return { copy: item.text ?? '', href: item.href ?? '' };
    });

export const getHomepageHeroTitle = (
  rawHeroTitle: string | null | undefined,
  rawExperimentalHeroTitle: NullableExperimentalHeroTitle,
  optimizely: ReactSDKClient | undefined
) => {
  if (rawExperimentalHeroTitle) {
    const selectedHeroTitle = (
      isVariationContainer(rawExperimentalHeroTitle as ExperimentalHeroTitle)
        ? chooseVariantForUser(
            optimizely,
            rawExperimentalHeroTitle as ExperimentalHeroTitleVariationContainer
          )
        : rawExperimentalHeroTitle
    ) as ExperimentalHeroTitleWithoutVariation;

    return selectedHeroTitle.title ?? defaultHeroTitle;
  }

  if (rawHeroTitle) {
    return rawHeroTitle;
  }

  return defaultHeroTitle;
};

export const normalizeCtaBlockData = (
  rawCtaBlock: NullableCtaBlock,
  optimizely: ReactSDKClient | undefined
): CtaBlockData => {
  const selectedCtaBlock = (
    isVariationContainer(rawCtaBlock as PossiblyExperimentalCtaBlock)
      ? chooseVariantForUser(
          optimizely,
          rawCtaBlock as CtaBlockVariationContainer
        )
      : rawCtaBlock
  ) as CtaBlockWithoutVariation;

  return {
    title: selectedCtaBlock.heading ?? defaultCtaBlockData.title,
    description:
      selectedCtaBlock.description ?? defaultCtaBlockData.description,
    // if copy or link is missing from Contentful, use the default instead to avoid a CTA with copy that doesn't match the link
    cta:
      selectedCtaBlock.callToAction && selectedCtaBlock.callToActionUrl
        ? {
            copy: selectedCtaBlock.callToAction,
            href: selectedCtaBlock.callToActionUrl,
          }
        : defaultCtaBlockData.cta,
    image: {
      desktop: {
        src: selectedCtaBlock.imageBlock?.image?.url ?? defaultLearnersDesktop,
        alt: selectedCtaBlock.imageBlock?.alt ?? '',
      },
      mobile: {
        src:
          selectedCtaBlock.imageBlocksCollection?.items[0]?.image?.url ??
          defaultLearnersMobile,
        alt: selectedCtaBlock.imageBlocksCollection?.items[0]?.alt ?? '',
      },
    },
  };
};

export const normalizeCarouselData = (
  rawCarouselData: NullableCarouselData,
  defaultCarouselData: CarouselItem[],
  optimizely: ReactSDKClient | undefined
) => {
  if (
    !rawCarouselData ||
    !(
      'type' in rawCarouselData &&
      (rawCarouselData.type === 'TitleFeaturesList' ||
        rawCarouselData.type === 'VariationContainer')
    )
  ) {
    return defaultCarouselData;
  }

  const selectedCarousel = (
    isVariationContainer(rawCarouselData as PossiblyExperimentalCarousel)
      ? chooseVariantForUser(
          optimizely,
          rawCarouselData as CarouselVariationContainer
        )
      : rawCarouselData
  ) as CarouselWithoutVariation;

  if (!selectedCarousel.featuresCollection) {
    return defaultCarouselData;
  }

  return selectedCarousel.featuresCollection.items.reduce<CarouselItem[]>(
    (acc, item) => {
      if (item && 'type' in item && item.type === 'CarouselSlide') {
        return [
          ...acc,
          {
            title: {
              copy: item.title ?? '',
              fontSize: {
                _: (item.baseBreakpoint?.titleFontSize as 16) ?? 44,
                sm: (item.smallBreakpoint?.titleFontSize as 16) ?? 72,
                md: (item.mediumBreakpoint?.titleFontSize as 16) ?? 44,
                lg: (item.largeBreakpoint?.titleFontSize as 16) ?? 64,
                xl: (item.extraLargeBreakpoint?.titleFontSize as 16) ?? 72,
              },
            },
            bullets: {
              copy: {
                _: getCaptions(item.baseBreakpoint?.captions),
                sm: getCaptions(item.smallBreakpoint?.captions),
                md: getCaptions(item.mediumBreakpoint?.captions),
                lg: getCaptions(item.largeBreakpoint?.captions),
                xl: getCaptions(item.extraLargeBreakpoint?.captions),
              },
            },
            img: {
              sources: {
                lg: item.largeBreakpoint?.slideImage?.image?.url ?? '',
                md: item.mediumBreakpoint?.slideImage?.image?.url ?? '',
                sm: item.smallBreakpoint?.slideImage?.image?.url ?? '',
                xl: item.extraLargeBreakpoint?.slideImage?.image?.url ?? '',
                xs: item.baseBreakpoint?.slideImage?.image?.url ?? '',
              },
              verticallyAlign: item.verticallyAlignImageWithTitle ?? true,
            },
          },
        ];
      }

      return acc;
    },
    []
  );
};

const getCaptions = (captions: { json: NodeData } | undefined | null) => {
  if (!captions || captions.json.nodeType !== BLOCKS.DOCUMENT) {
    return [];
  }

  const finalCaptions: string[] = [];

  for (const outerNode of (captions.json as Document).content) {
    if (outerNode.nodeType === 'paragraph') {
      for (const innerNode of outerNode.content) {
        if (innerNode.nodeType === 'text') {
          finalCaptions.push(innerNode.value);
        }
      }
    }
  }

  return finalCaptions;
};
