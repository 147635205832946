import {
  Box,
  Column,
  FillButton,
  FlexBox,
  GridBox,
  IconButton,
  Logo,
  Overlay,
  Text,
} from '@codecademy/gamut';
import { CheckFilledIcon, MiniDeleteIcon } from '@codecademy/gamut-icons';
import styled from '@emotion/styled';
import { LoginOrRegister } from '@mono/ui-login-or-register';
import React from 'react';

import { trackUserClick } from '../../../tracking';
import confetti from '../assets/Confetti.svg';
import type { QuestionResponses } from '../types';
import { useCareerInterestRelatedTopics } from './hooks';
import whatsPossible from './whatsPossible.json';

const StyledLogo = styled(Logo)`
  background-color: rgb(243, 233, 223);
`;

const StyledColumn = styled(Column)`
  background-color: ${({ theme }) => theme.colors['navy-100']};
`;

const Image = styled(Box)`
  pointer-events: none;
  transform: translateY(-100%);
`.withComponent('img');

// export for test only
export const getWhatsPossible = (quizAnswers: QuestionResponses) => {
  if (quizAnswers.knowledge && quizAnswers.purpose) {
    const key = `${quizAnswers.knowledge}-${quizAnswers.purpose}`.toLowerCase();
    return [
      ...whatsPossible[key as keyof typeof whatsPossible],
      'Join a vibrant community of millions learning with you',
    ];
  }
  return [
    ...whatsPossible['beginner-not_sure'],
    'Join a vibrant community of millions learning with you',
  ];
};

export const Results: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  quizAnswers: QuestionResponses;
  isUserInIndia: boolean;
}> = ({ isOpen, onRequestClose, quizAnswers, isUserInIndia }) => {
  const relatedTopics = useCareerInterestRelatedTopics(
    quizAnswers.careerInterest
  );

  return (
    <Overlay onRequestClose={onRequestClose} isOpen={isOpen}>
      <Box
        bg="beige"
        height="100vh"
        width="100vw"
        overflow="auto"
        position="relative"
      >
        <GridBox
          height="100%"
          gridTemplateColumns={{ _: '1fr', md: 'repeat(12, 1fr)' }}
          gridTemplateRows={{ _: 'min-content', md: '1fr' }}
        >
          <FlexBox
            justifyContent="space-between"
            position={{ md: 'absolute' }}
            width="100vw"
            px={{
              _: 32,
              sm: 64,
              xl: 96,
            }}
            pt={32}
            bg={{ _: 'navy-100', md: 'transparent' }}
            zIndex={1}
          >
            <StyledLogo />
            <IconButton
              icon={MiniDeleteIcon}
              size="small"
              onClick={onRequestClose}
              tip="Close quiz results"
              tipProps={{ alignment: 'bottom-center' }}
            />
          </FlexBox>
          <StyledColumn
            size={{ md: 7 }}
            alignItems="center"
            py={{ _: 48, md: 0 }}
            px={{ _: 32, sm: 0 }}
          >
            <FlexBox
              position="relative"
              flexDirection="column"
              alignItems="center"
            >
              <Image
                display={{ _: 'none', md: 'block' }}
                src={confetti}
                position="absolute"
                top={24}
                px={24}
                width={
                  // unfortunate cast as this component is expecting the width property type for
                  // img elements despite this functioning as the width prop for Box components
                  { md: '100%', lg: 600 } as unknown as string
                }
                alt=""
              />
              <FlexBox alignItems="center">
                <Box maxWidth={{ _: '100%', xs: 460, md: 424 }}>
                  <Text as="h2" variant="title-xl" mb={16}>
                    We picked courses just for you
                  </Text>
                  <Text as="h3" variant="p-base" mb={8}>
                    Skills that match your interests
                  </Text>
                  <FlexBox
                    as="ul"
                    listStyleType="none"
                    gap={8}
                    p={0}
                    m={0}
                    flexWrap="wrap"
                  >
                    {relatedTopics.map((topic) => (
                      <Box key={topic.path} as="li">
                        <FillButton
                          variant="secondary"
                          size="small"
                          href={topic.path}
                          onClick={() =>
                            trackUserClick({
                              page_name: 'homepage',
                              context: 'onboarding_quiz',
                              target: topic.title,
                            })
                          }
                        >
                          {topic.title}
                        </FillButton>
                      </Box>
                    ))}
                  </FlexBox>
                  <Text
                    as="h3"
                    variant="p-base"
                    fontWeight={700}
                    mt={48}
                    mb={8}
                  >
                    Discover what&apos;s possible
                  </Text>
                  <FlexBox
                    as="ul"
                    listStyleType="none"
                    flexDirection="column"
                    gap={8}
                    p={0}
                    m={0}
                  >
                    {getWhatsPossible(quizAnswers)?.map((item) => (
                      <FlexBox key={item} as="li">
                        <CheckFilledIcon
                          size={20}
                          color="yellow"
                          bg="navy"
                          mr={12}
                          p={1 as 0}
                          borderRadius="full"
                          flexShrink={0}
                        />
                        <Text>{item}</Text>
                      </FlexBox>
                    ))}
                  </FlexBox>
                </Box>
              </FlexBox>
            </FlexBox>
          </StyledColumn>
          <Column size={{ md: 5 }} alignItems="center">
            <FlexBox
              justifyContent="center"
              justifySelf="center"
              mx={32}
              py={32}
              maxWidth="22rem"
            >
              <LoginOrRegister
                variant="basic"
                redirectUrl="/learn"
                renderTitle={(_, isLogin) => (
                  <Text as="h1" variant="title-md" lineHeight="base" pb={16}>
                    {isLogin
                      ? 'Log in'
                      : 'Create a free account to see your matches'}
                  </Text>
                )}
                captureMobile={isUserInIndia}
              />
            </FlexBox>
          </Column>
        </GridBox>
      </Box>
    </Overlay>
  );
};
