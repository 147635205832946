import { getAssetUrl } from '@mono/util-url';

export type Logo = {
  src: string;
  alt: string;
};

const getHomepageLogosAssetUrl = (company: string) =>
  `${getAssetUrl(`homepage/logos-section/${company} simple white logo.svg`)}`;

export const logosList: Logo[] = [
  {
    src: getHomepageLogosAssetUrl('Meta'),
    alt: 'Meta',
  },
  {
    src: getHomepageLogosAssetUrl('Microsoft'),
    alt: 'Microsoft',
  },
  {
    src: getHomepageLogosAssetUrl('Google'),
    alt: 'Google',
  },
  {
    src: getHomepageLogosAssetUrl('EA'),
    alt: 'Electronic Arts',
  },
  {
    src: getHomepageLogosAssetUrl('Apple'),
    alt: 'Apple',
  },
  {
    src: getHomepageLogosAssetUrl('Instagram'),
    alt: 'Instagram',
  },
  {
    src: getHomepageLogosAssetUrl('Spotify'),
    alt: 'Spotify',
  },
  {
    src: getHomepageLogosAssetUrl('Reddit'),
    alt: 'Reddit',
  },
  {
    src: getHomepageLogosAssetUrl('IBM'),
    alt: 'IBM',
  },
];
