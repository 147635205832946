import { Box, Column, FillButton } from '@codecademy/gamut';
import { getCatalogBasePath } from '@mono/util-url';

import { trackUserClick } from '../../../tracking';
import { Goal } from '..';
import { Cards } from './Cards';

export interface CardLinksProps {
  content: Goal['relatedContent'];
}

export const CardLinks: React.FC<React.PropsWithChildren<CardLinksProps>> = ({
  content,
}) => (
  <>
    <Box position="relative">
      <Cards content={content} />
    </Box>

    <Column size={12} my={24}>
      <FillButton
        mx={{ _: 0, xs: 'auto' }}
        href={getCatalogBasePath()}
        onClick={() =>
          trackUserClick({
            target: 'catalog',
            context: 'homepage_browse_catalog',
          })
        }
        width="min-content"
        data-testid="browse-catalog-link"
      >
        Explore full catalog <span aria-hidden>&nbsp;→</span>
      </FillButton>
    </Column>
  </>
);
