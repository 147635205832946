import {
  Box,
  ContentContainer,
  FlexBox,
  GridBox,
  Input,
  Text,
} from '@codecademy/gamut';
import { SearchIcon } from '@codecademy/gamut-icons';
import { css, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { LoadingCurriculumCard, searchWorker } from '@mono/brand';
import { CurriculumCardData } from '@mono/data-curriculum-cards';
import { getWelcomeOnboarding } from '@mono/util-url';
import { useEffect, useState } from 'react';

import { convertRawHomepageSearchResults } from '../../helpers/convertRawHomepageSearchResults';
import { trackingPageName } from '../../tracking';
import { SimpleCurriculumCard } from '../SimpleCurriculumCard';
import { WhiteTextAnchor } from '../styles';

const SearchInput = styled(Input)(
  css({
    fontFamily: 'accent',
    fontSize: { _: 16, sm: 22 },
    borderRadius: 'xl',
    bg: 'white-100',
    '&::placeholder': {
      textColor: theme.colors['text-secondary'] as never,
    },
    p: 32,
  })
);

export const SearchWithFeaturedContainers: React.FC<{
  featuredContainers: CurriculumCardData[];
}> = ({ featuredContainers }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [queriedContainers, setQueriedContainers] = useState<
    CurriculumCardData[] | null
  >(null);
  const containersToShow =
    queriedContainers && queriedContainers.length > 0
      ? queriedContainers
      : featuredContainers;
  const searchQueryExists = searchQuery.trim().length > 0;
  const showLoadingState = queriedContainers === null && searchQueryExists;

  useEffect(() => {
    searchWorker.init();
  }, []);

  useEffect(() => {
    if (!searchQueryExists) {
      return;
    }

    setQueriedContainers(null);

    let abort = false;
    const resultsPromise = searchWorker.homepageSearch(searchQuery);
    const delay = new Promise((resolve) => setTimeout(resolve, 300));

    Promise.all([resultsPromise, delay]).then(([results]) => {
      if (abort) {
        return;
      }
      const formattedResults = convertRawHomepageSearchResults(results.top);
      setQueriedContainers(formattedResults);
    });

    return () => {
      abort = true;
    };
  }, [searchQuery, searchQueryExists]);

  return (
    <ContentContainer py={64}>
      <Box px={{ _: 12, sm: 48 }}>
        <Text
          as="h2"
          fontFamily="accent"
          variant="title-lg"
          fontWeight="normal"
        >
          Explore your interests
        </Text>

        <Box role="search" position="relative" py={40}>
          <SearchInput
            placeholder="What do you want to learn?"
            type="search"
            width="100%"
            autoComplete="off"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Box position="absolute" right={32} bottom={70}>
            <SearchIcon
              color={searchQueryExists ? 'white' : 'text-secondary'}
              size="24"
            />
          </Box>
        </Box>
        <Text
          as="h3"
          fontFamily="accent"
          variant="p-large"
          color="text-secondary"
          pb={16}
          textAlign="center"
        >
          Top courses
        </Text>
        <GridBox
          m={0}
          p={0}
          as="ul"
          listStyle="none"
          gridTemplateColumns={{ sm: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
          gap={24}
          aria-live="polite"
        >
          {searchQueryExists && (
            <Text screenreader>
              {showLoadingState
                ? 'Loading'
                : `Search results loaded for ${searchQuery}.`}
            </Text>
          )}
          <>
            {showLoadingState
              ? Array.from({ length: 4 }).map((_, index) => (
                  <FlexBox
                    // eslint-disable-next-line react/no-array-index-key
                    key={`loading-${index}`}
                    width="auto"
                    height="100%"
                    minHeight={302}
                    as="li"
                    justifyContent="center"
                    alignItems="center"
                    data-testid="loading-curriculum-card"
                  >
                    <LoadingCurriculumCard variant="navy" />
                  </FlexBox>
                ))
              : containersToShow.map((content) => (
                  <FlexBox
                    key={content.id}
                    width="auto"
                    height="100%"
                    minHeight={302}
                    as="li"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <SimpleCurriculumCard
                      headingLevel="h4"
                      content={content}
                      // TODO: add tracking data
                      trackingData={{
                        page_name: trackingPageName,
                      }}
                    />
                  </FlexBox>
                ))}
          </>
        </GridBox>
        <Box pt={40} textAlign="center">
          <Text variant="p-large" display="inline">
            Need more help figuring out what you want to learn?&nbsp;
          </Text>
          <WhiteTextAnchor
            fontSize={18}
            href={getWelcomeOnboarding()}
            display="inline"
          >
            Take our quiz
          </WhiteTextAnchor>
        </Box>
      </Box>
    </ContentContainer>
  );
};
