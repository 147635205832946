import {
  Anchor,
  Box,
  Column,
  ContentContainer,
  FlexBox,
  LayoutGrid,
  Text,
  TextButton,
} from '@codecademy/gamut';
import { CheckerRegular } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { getLearnerStoriesPath } from '@mono/util-url';
import { useRef } from 'react';

import { trackUserClick } from '../../../tracking';

export interface LearnerStory {
  heading: string;
  description: string;
  callToActionUrl: string;
  imageUrl: string;
}
export interface LearnerStoriesSection {
  heading: string;
  description: string;
  stories: LearnerStory[];
}

const HeaderContainer = styled(ContentContainer)`
  margin-bottom: 2rem;
  padding-top: 4rem;
`;

const StyledImage = styled.img`
  border: 1px solid ${theme.colors['border-primary']};
  max-width: 40rem;
  ${theme.breakpoints.xl} {
    max-width: 18rem;
    max-height: 18rem;
  }
  ${theme.breakpoints.sm} {
    max-height: 18rem;
  }
  object-fit: cover;
`;

const StyledCheckerRegular = styled(CheckerRegular)`
  position: absolute;
  height: 5rem;
  width: 100%;
  top: -1rem;
  z-index: -1;

  ${({ theme }) => theme.breakpoints.xl} {
    height: 10rem;
  }
`;

const trackExploreClick = () =>
  trackUserClick({
    target: 'explore_more_stories_CTA',
    context: 'learner_stories',
  });

export const LearnerStories: React.FC<LearnerStoriesSection> = ({
  heading,
  description,
  stories,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Box ref={ref}>
      <HeaderContainer>
        <Text as="h2" fontSize={{ _: 34, sm: 44 }} pb={{ _: 8, lg: 0 }}>
          {heading}
        </Text>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Text variant="p-large">{description}</Text>
          {/* Button visible on tablet and desktop */}
          <TextButton
            display={{ _: 'none', sm: 'block' }}
            href={getLearnerStoriesPath()}
            onClick={() => trackExploreClick()}
          >
            Explore more stories →
          </TextButton>
        </FlexBox>
      </HeaderContainer>
      <Box position="relative" pb={{ _: 32, sm: 64 }}>
        <StyledCheckerRegular />
        <ContentContainer>
          <LayoutGrid>
            <Column size={12} gridRow={{ xs: 2, sm: 3 }}>
              <LayoutGrid as="ul" rowGap={32} columnGap={16} m={0} p={0}>
                {stories?.map((story) => (
                  <Column
                    as="li"
                    size={{ xs: 12, sm: 6, lg: 3 }}
                    key={story.heading}
                  >
                    <Anchor
                      data-testid="learner-card"
                      href={story.callToActionUrl}
                      onClick={() =>
                        trackUserClick({
                          target: 'story',
                          context: 'learner_stories',
                          name: story.heading,
                        })
                      }
                      variant="interface"
                    >
                      <FlexBox flexDirection="column">
                        <StyledImage
                          alt="learner portrait"
                          src={story.imageUrl}
                          loading="lazy"
                        />
                        <Text variant="title-sm" pt={8} pb={4} maxWidth={309}>
                          {story.heading}
                        </Text>
                        <Text
                          variant="p-small"
                          fontFamily="accent"
                          maxWidth={279}
                        >
                          {story.description}
                        </Text>
                      </FlexBox>
                    </Anchor>
                  </Column>
                ))}
              </LayoutGrid>
            </Column>
          </LayoutGrid>
        </ContentContainer>
      </Box>
      {/* Button visible on mobile */}
      <TextButton
        display={{ _: 'block', sm: 'none' }}
        href={getLearnerStoriesPath()}
        onClick={() => trackExploreClick()}
        mx="auto"
        mb={32}
        width="min-content"
      >
        Explore more stories →
      </TextButton>
    </Box>
  );
};
