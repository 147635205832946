import { Box } from '@codecademy/gamut';
import { CurriculumCardData } from '@mono/data-curriculum-cards';
import React from 'react';

import { DotLooseDivider } from './components/DotLooseDivider';
import { FooterCTA } from './components/FooterCTA';
import { Hero } from './components/Hero';
import { LearnerStories } from './components/LearnerStories';
import { LearnersWorkAt } from './components/LearnersWorkAt';
import {
  LegacyHomepage,
  LegacyHomepageProps,
} from './components/LegacyHomepage';
import { SearchWithFeaturedContainers } from './components/SearchWithFeaturedContainers';
import { SkillsMenuSection } from './components/SkillsMenuSection';

export interface HomepageProps extends LegacyHomepageProps {
  showRevampedHomepage: boolean;
  featuredContainers: CurriculumCardData[];
}

export const Homepage: React.FC<HomepageProps> = ({
  contentfulData,
  isUserInIndia,
  products,
  showSignUpCtaVariant,
  showRevampedHomepage,
  featuredContainers,
}) => {
  return (
    <Box
      as="main"
      overflowX={{ _: 'hidden', md: 'initial' }}
      data-testid="homepage-container"
      id="heading"
    >
      {showRevampedHomepage ? (
        <>
          <Hero />
          <LearnersWorkAt />
          <DotLooseDivider />
          <SearchWithFeaturedContainers
            featuredContainers={featuredContainers}
          />
          <SkillsMenuSection />
          <LearnerStories {...contentfulData.learnerStories} />
          <FooterCTA />
        </>
      ) : (
        <LegacyHomepage
          contentfulData={contentfulData}
          isUserInIndia={isUserInIndia}
          products={products}
          showSignUpCtaVariant={showSignUpCtaVariant}
        />
      )}
    </Box>
  );
};
