import {
  Box,
  Column,
  ContentContainer,
  FlexBox,
  LayoutGrid,
  Text,
} from '@codecademy/gamut';
import { Background } from '@codecademy/gamut-styles';
import React from 'react';

type CredibilityDataType = {
  firstLine: string;
  secondLine: string;
}[];

const globalCredibilityData: CredibilityDataType = [
  { firstLine: '50M', secondLine: 'Learners' },
  { firstLine: '190+', secondLine: 'Countries' },
  { firstLine: '3.7B', secondLine: 'Code submits' },
];

const indiaCredibilityData: CredibilityDataType = [
  { firstLine: '5 Cr+', secondLine: 'Learners' },
  { firstLine: '190+', secondLine: 'Countries' },
  { firstLine: '300 Cr+', secondLine: 'Code submits' },
];

export const Credibility: React.FC<{
  isUserInIndia: boolean;
}> = ({ isUserInIndia }) => {
  const credibilityData = isUserInIndia
    ? indiaCredibilityData
    : globalCredibilityData;

  return (
    <Background bg={isUserInIndia ? 'hyper' : 'yellow'}>
      <ContentContainer>
        <LayoutGrid py={{ _: 32, xs: 96 }}>
          <Column
            pt={{ _: 32, xs: 0 }}
            offset={{ _: 0, xs: 1 }}
            size={{ _: 12, xs: 4 }}
          >
            <Text
              fontSize={{
                _: 34,
                xs: 44,
              }}
              fontWeight={700}
              lineHeight="title"
              as="h2"
              whiteSpace="pre-wrap"
            >
              {'Join in on\nsomething big'}
            </Text>
          </Column>
          <Column
            offset={{ _: 0, xs: 7, sm: 6 }}
            size={{ _: 12, xs: 5, sm: 6 }}
            py={{ _: 32, xs: 0 }}
            mt={{ _: 32, xs: 0 }}
          >
            <FlexBox
              textAlign="center"
              gap={16}
              justifyContent="space-between"
              alignItems="center"
              height="100%"
              flexDirection={{ _: 'row', xs: 'column', sm: 'row' }}
            >
              {credibilityData.map(({ firstLine, secondLine }) => (
                <Box key={firstLine}>
                  <Text
                    fontSize={{ _: 34, md: 44 }}
                    fontWeight={700}
                    lineHeight="title"
                  >
                    {firstLine}
                  </Text>
                  <br />
                  <Text
                    fontSize={{ _: 16, md: 22 }}
                    fontWeight={700}
                    lineHeight="title"
                  >
                    {secondLine}
                  </Text>
                </Box>
              ))}
            </FlexBox>
          </Column>
        </LayoutGrid>
      </ContentContainer>
    </Background>
  );
};
