import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientService } from '@mono/apollo';
import { HomepageAllCategoriesDataQuery } from '@mono/data-codegen/client/graphql-gateway/graphql';
import { fetchCacher } from '@mono/data-fetch-cachers';
import { logError } from '@mono/data-logging';

import { CategoryData } from '../components/GoalSection';
import { homepageAllCategoriesDataQuery } from '../queries.graphql-gateway';

export const fetchAllCategoriesDataCached = async (
  client: ApolloClient<NormalizedCacheObject>
) => {
  const f = () => fetchAllCategoriesData(client);
  return fetchCacher('homepage-all-categories', f, {
    revalidateMs: 60 * 60 * 1000, // 1 hour
  });
};

export const fetchAllCategoriesData = async (
  client: ApolloClient<NormalizedCacheObject>
) => {
  try {
    const {
      data: { categories },
    } = await client.query<HomepageAllCategoriesDataQuery>({
      context: { service: ApolloClientService.GraphqlGateway },
      query: homepageAllCategoriesDataQuery,
    });

    return categories.reduce<Record<string, CategoryData>>(
      (acc, item) => ({
        ...acc,
        [item.slug]: {
          title: item.title ?? '',
          slug: item.slug ?? '',
          categoryType: item.classification ?? '',
        },
      }),
      {}
    );
  } catch (error) {
    logError(error, {
      message: 'Unable to fetch categories data from gateway',
    });
    return {};
  }
};
